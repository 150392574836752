exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-catriona-neville-js": () => import("./../../../src/pages/about-us/catriona-neville.js" /* webpackChunkName: "component---src-pages-about-us-catriona-neville-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-karen-young-js": () => import("./../../../src/pages/about-us/karen-young.js" /* webpackChunkName: "component---src-pages-about-us-karen-young-js" */),
  "component---src-pages-about-us-tamsin-gwynn-js": () => import("./../../../src/pages/about-us/tamsin-gwynn.js" /* webpackChunkName: "component---src-pages-about-us-tamsin-gwynn-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-specialist-facial-therapy-js": () => import("./../../../src/pages/specialist-facial-therapy.js" /* webpackChunkName: "component---src-pages-specialist-facial-therapy-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-what-to-expect-js": () => import("./../../../src/pages/what-to-expect.js" /* webpackChunkName: "component---src-pages-what-to-expect-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */)
}

